<template>
  <div class="typePixel-setting">
    <div class="header">
      <div class="titre">
        <h3>Types des Pixels</h3>
      </div>
      <div class="button">
        <b-button
          size="sm"
          variant="light"
          v-if="sortDesc"
          class="searchIcon padd-btn"
          @click="sortBy"
        >
          <font-awesome-icon icon="caret-up" />
        </b-button>
        <b-button
          size="sm"
          variant="light"
          v-else
          class="searchIcon padd-btn"
          @click="sortBy"
        >
          <font-awesome-icon icon="caret-down" />
        </b-button>
        <b-button
          size="sm"
          variant="success"
          v-b-modal.ModalAddTypePixel
          class="searchIcon"
        >
          <font-awesome-icon class="" icon="plus" />
          Ajouter un Type de Pixel
        </b-button>
      </div>
    </div>
    <div
      v-if="!initLoading && getTypePixelLoading"
      class="init-loading three-dots-loading"
    >
      Chargement en cours
    </div>
    <hr />
    <div v-if="initLoading" class="init-loading three-dots-loading">
      Chargement en cours
    </div>
    <b-list-group v-else>
      <b-list-group-item
        href="#"
        :class="typePixel.visible ? '' : 'collapsed'"
        :aria-controls="'collapse-' + index"
        @click="typePixel.visible = !typePixel.visible"
        v-for="(typePixel, index) in getListeTypePixel"
        :key="index"
        :value="index.id"
      >
        <div class="content">
          <div class="gras">
            {{ typePixel.name }}
          </div>

          <div class="right">
            <div class="actions">
              <b-button
                size="sm"
                variant="primary"
                title="Modifier"
                @click.prevent.stop="hundleUpdateClick(typePixel)"
              >
                <font-awesome-icon class icon="pencil-alt" />
              </b-button>

              <b-button
                size="sm"
                title="Supprimer"
                variant="danger"
                @click.prevent.stop="hundleDeleteClick(typePixel)"
              >
                <font-awesome-icon class icon="trash-alt" />
              </b-button>
            </div>
            <div class="icon">
              <font-awesome-icon class="icon1" icon="angle-up" />
              <font-awesome-icon class="icon1" icon="angle-down" />
            </div>
          </div>
        </div>
        <b-collapse
          :id="'collapse-' + index"
          v-model="typePixel.visible"
          class="mt-2"
        >
          <b-card>
            <b-row>
              <b-col>
                <div>
                  <strong>PIXEL ID : </strong>
                  <span>{{ typePixel.pixel_id }}</span>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-collapse>
      </b-list-group-item>
      <b-list-group-item
        v-if="!getListeTypePixel || !getListeTypePixel.length"
        class="aucun-type-pixel"
      >
        Aucun Type des Pixels
      </b-list-group-item>
    </b-list-group>

    <!-- Add typePixel Modal -->
    <b-modal
      no-close-on-backdrop
      id="ModalAddTypePixel"
      ref="ModalAddTypePixel"
      title="Ajouter un Type de Pixel"
      :hide-footer="true"
      @show="resetModal"
      @hidden="resetModal"
    >
      <form @submit.prevent="handleSubmit">
        <b-form-group label="Nom" label-for="Nom-input">
          <b-form-input
            id="Nom-input"
            v-model="typePixelToAdd.name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="PIXEL ID" label-for="Code Postal-input">
          <b-form-input
            id="Code Postal-input"
            v-model="typePixelToAdd.pixel_id"
            required
          ></b-form-input>
        </b-form-group>
        <!-- Add typePixel Modal: messages -->
        <div class="message">
          <div v-if="getTypePixelLoading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="getTypePixelError" class="error">
            <ul v-if="Array.isArray(getTypePixelError)">
              <li v-for="(e, index) in getTypePixelError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getTypePixelError }}</span>
          </div>
        </div>
        <!-- Add type Pixel Modal: buttons -->

        <div class="form-actions">
          <b-button
            @click="hideModal('ModalAddTypePixel')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Enregistrer
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- END Add typePixel Modal -->

    <!-- Update typePixel Modal -->
    <b-modal
      no-close-on-backdrop
      id="ModalUpdateTypePixel"
      ref="ModalUpdateTypePixel"
      title="Modifier un type de pixel"
      :hide-footer="true"
      @hidden="resetModal"
    >
      <form v-if="typePixelToUpdate" @submit.prevent="handleSubmitUpdate">
        <b-form-group label="Nom" label-for="nom-input-update">
          <b-form-input
            id="Nom-input"
            v-model="typePixelToUpdate.name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="PIXEL ID" label-for="cp-input-update">
          <b-form-input
            id="Code Postal-input"
            v-model="typePixelToUpdate.pixel_id"
            required
          ></b-form-input>
        </b-form-group>
        <!--  type Pixel Modal: messages -->
        <div class="message">
          <div v-if="getTypePixelLoading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="getTypePixelError" class="error">
            <ul v-if="Array.isArray(getTypePixelError)">
              <li v-for="(e, index) in getTypePixelError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getTypePixelError }}</span>
          </div>
        </div>
        <!-- Update type Pixel Modal: buttons -->
        <div class="form-actions">
          <b-button
            @click="hideModal('ModalUpdateTypePixel')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Enregistrer
          </b-button>
        </div>
      </form>
    </b-modal>
    <!-- END Update typePixel Modal -->

    <!-- Delete typePixel Modal -->
    <b-modal
      no-close-on-backdrop
      ref="ModalDeleteTypePixel"
      id="ModalDeleteTypePixel"
      title=" Supprimer un type de pixel"
      :hide-footer="true"
    >
      <p>
        Êtes-vous certain de vouloir supprimer le type de pixel
        <strong> {{ typePixelToDelete ? typePixelToDelete.name : '' }}</strong>
        ?
      </p>
      <div class="message">
        <div v-if="getTypePixelLoading" class="three-dots-loading">
          Chargement en cours
        </div>
        <div v-if="getTypePixelError" class="error">
          <ul v-if="Array.isArray(getTypePixelError)">
            <li v-for="(e, index) in getTypePixelError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getTypePixelError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          @click="hideModal('ModalDeleteTypePixel')"
          variant="outline-secondary"
        >
          Annuler
        </b-button>
        <b-button @click="handleDeleteTypePixel" variant="success">
          Valider
        </b-button>
      </div>
    </b-modal>
    <!-- END Delete typePixel Modal -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      typePixelToAdd: {
        name: null,
        pixel_id: null
      },
      typePixelToUpdate: null,
      typePixelToDelete: null,
      initLoading: true,
      full_name: null,
      sortDesc: false,
      sort_by: 'full_name',
      sort_by_desc: 'full_name'
    }
  },
  methods: {
    ...mapActions([
      'fetchListTypePixel',
      'addTypePixel',
      'updateTypePixel',
      'deleteTypePixel',
      'resetErrorTypePixel'
    ]),
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },
    async hundleUpdateClick(pixelType) {
      this.typePixelToUpdate = pixelType
      this.$refs['ModalUpdateTypePixel'].show()
    },
    hundleDeleteClick(pixelType) {
      this.typePixelToDelete = pixelType
      this.$refs['ModalDeleteTypePixel'].show()
    },
    async handleDeleteTypePixel() {
      const response = await this.deleteTypePixel(this.typePixelToDelete)
      if (response) {
        this.hideModal('ModalDeleteTypePixel')
      }
    },
    resetModal() {
      this.typePixelToAdd = {
        name: null,
        pixel_id: null
      }
      this.typePixelToUpdate = null
      this.typePixelToDelete = null
      this.resetErrorTypePixel()
    },
    async handleSubmit() {
      const payload = {
        name: this.typePixelToAdd.name,
        pixel_id: this.typePixelToAdd.pixel_id
      }
      const response = await this.addTypePixel(payload)
      if (response) {
        this.hideModal('ModalAddTypePixel')
      }
    },
    async handleSubmitUpdate() {
      const response = await this.updateTypePixel(this.typePixelToUpdate)
      if (response) {
        this.hideModal('ModalUpdateTypePixel')
      }
    },
    async filter() {
      if (!this.sortDesc) {
        this.fetchListTypePixel({
          sort_by: this.sort_by,
          full_name: this.full_name
        })
      } else {
        this.fetchListTypePixel({
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name
        })
      }
    },
    sortBy() {
      this.sortDesc = !this.sortDesc
      if (!this.sortDesc) {
        this.fetchListTypePixel({
          sort_by: this.sort_by,
          full_name: this.full_name
        })
      } else {
        this.fetchListTypePixel({
          sort_by_desc: this.sort_by_desc,
          full_name: this.full_name
        })
      }
    }
  },
  computed: {
    ...mapGetters([
      'getTypePixelLoading',
      'getTypePixelError',
      'getListeTypePixel'
    ])
  },
  async mounted() {
    await this.fetchListTypePixel({
      sort_by: this.sort_by
    })
    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.typePixel-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .button {
      display: flex;
      .searchIcon {
        border-radius: unset;
        box-shadow: unset;
        cursor: pointer;
        border: 1px solid #6c757d59;
        &:focus {
          box-shadow: unset;
        }
        &.padd-btn {
          padding: 0px 9px;
        }
      }
    }
  }
  .list-group {
    .aucun-type-pixel {
      text-align: center;
      color: #dc3545;
      //   font-weight: bold;
    }
    .list-group-item {
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .gras {
          text-transform: capitalize;
          font-weight: bold;
        }
        .right {
          display: flex;
          justify-content: flex-end;

          .actions {
            display: block;
            margin-right: 20px;
            button {
              margin: 0 2px;
            }
          }
          .icon {
            display: flex;
            flex-direction: column-reverse;
            margin-right: 16px;
          }
        }
      }
      .mt-2 {
        ul {
          margin-top: 5px;
        }
      }

      &.collapsed {
        .icon {
          flex-direction: column !important;
        }
      }
    }
  }
}
</style>
<style lang="scss">
#ModalAddTypePixel {
  .type {
    font-weight: normal;
    text-transform: capitalize;
    font-size: 12px;
    color: #317ae2;
  }
  .aucunTechnicien {
    color: #2196f3;
    margin-left: 35px;
  }
  .icon-exclamation {
    text-decoration: line-through;
    text-decoration-color: #e73e01;
  }
  .custom-control-inline {
    display: block;
  }
}
#ModalUpdateTypePixel {
  .type {
    font-weight: normal;
    text-transform: capitalize;
    font-size: 12px;
    color: #317ae2;
  }
  .aucunTechnicien {
    color: #2196f3;
    margin-left: 35px;
  }
  .icon-exclamation {
    text-decoration: line-through;
    text-decoration-color: #e73e01;
  }
  .custom-control-inline {
    display: block;
  }
}
</style>
